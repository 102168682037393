/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/fa-light-300.eot");
  src: url("../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype"), url("../fonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }


@font-face {
    font-family: 'ProximaNova-Regular';
    src: url('/theme/US/site_1/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), url('/theme/US/site_1/fonts/ProximaNova-Regular.otf') format('opentype'), url('/theme/US/site_1/fonts/ProximaNova-Regular.woff') format('woff'), url('/theme/US/site_1/fonts/ProximaNova-Regular.ttf') format('truetype'), url('/theme/US/site_1/fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
